*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main {
    flex: 1;
}

#footer {
    border-top: 1px solid rgba(0, 0, 0, .1);
    color: var(--tag_color_gray);
    margin-top: 1rem;
    padding: 1rem 0;
}

[class^=message] {
    z-index: 99;
}

#printable {
    pointer-events: none;
    overflow: hidden;
    height: 1px;
}

.printable_only { display: none; }

@media print {
    #app { display: none; }
    .printable_only { display: block; }
    .screen_only { display: none; }

    #printable {
        height: auto;
        overflow: visible;
    }
}
